<template>
  <div class="box-padding activity-bottom">
    <div class="draw-rules">
      <img src="~images/dow-ceremony/draw-rules-left.png" style="float:left;" alt="">
      <h3>每期最高获得10次抽奖机会</h3><img src="~images/dow-ceremony/draw-rules-right.png" alt="" style="float:right;">
    </div>
    <div class="table-activity">
      <table>
        <tbody>
          <tr>
            <th>段位</th>
            <th>月度排名积分</th>
            <th>获得抽奖次数</th>
          </tr>
          <tr>
            <td>王者</td>
            <td>2000分及以上</td>
            <td>+4次</td>
          </tr>
          <tr>
            <td>钻石</td>
            <td>1500- 1999分</td>
            <td>+2次</td>
          </tr>
          <tr>
            <td>铂金</td>
            <td>1000 - 1499分</td>
            <td>+2次</td>
          </tr>
          <tr>
            <td>黄金</td>
            <td>500 - 999分</td>
            <td>+1次</td>
          </tr>
          <tr>
            <td>白银</td>
            <td>200 - 499分</td>
            <td>+1次</td>
          </tr>
          <tr>
            <td>青铜 </td>
            <td>0– 199分</td>
            <td>0次</td>
          </tr>
        </tbody>
      </table>
    </div>
    <p>剩余的抽奖次数会在
      <b>“立即抽奖”</b>上显示哦</p>
    <p>赶紧去完成任务获得更多积分吧！</p>
  </div>
</template>

<script>
export default {
  data () {
    return {}
  },
  mounted () {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }
}
</script>

<style lang="scss" scoped>
@import "~styles/index.scss";
.table-activity {
  width: px2rem(690px);
  line-height: px2rem(80px);
  margin: px2rem(20px) 0 px2rem(60px) 0;
  & table {
    width: 100%;
    text-align: center;
    & th {
      height: px2rem(80px);
      background: #f0f0f0;
      line-height: px2rem(80px);
      border: 1px solid $color-line;
      width: 33.33%;
    }
    & td {
      line-height: px2rem(80px);
      border: 1px solid $color-line;
      width: 33.33%;
    }
  }
}
.activity-bottom {
  margin-bottom: px2rem(103px);
  font-size: px2rem(28px);
  text-align: center;
  line-height: px2rem(38px);
  b {
    font-weight: bold;
  }
  p {
   margin-bottom: px2rem(20px);
  }
}
.draw-rules {
  margin-top: 0.866rem;
  text-align: center;
  display: inline-block;
  height: px2rem(42px);
  h3 {
    float: left;
    // margin-top: -0.13rem;
    font-weight: bold;
    font-size: px2rem(26px);
    margin: px2rem(-18px) px2rem(20px) 0 px2rem(20px);
  }
}
</style>
